import React, { useContext } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { v4 } from "uuid"
import { toast } from "react-toastify"
import NumberFormat from "react-number-format"

import { AppContext } from "components/Context/AppContext"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import Button from "components/Button/Button"

import {
  getFormattedCart,
  getUpdatedItems,
  isUserLoggedIn,
} from "utils/functions"

import GET_CART from "queries/get-cart"
import UPDATE_CART from "mutations/update-cart"

const CartContent = () => {
  const isLoggedIn = isUserLoggedIn()
  const [cart, setCart] = useContext(AppContext)

  const totalParsed = parseInt(
    cart?.totalSubProductsPrice.match(/\d|\./g).join("")
  )
  const productsCount =
    null !== cart && Object.keys(cart).length ? cart.totalProductsPrice : ""

  // Get Cart Data
  const { data, refetch } = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      const updatedCart = getFormattedCart(data)
      localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart))
      setCart(updatedCart)
    },
    onError: error => {
      console.warn(error)
    },
  })

  // Update Cart Mutation
  const [updateCart] = useMutation(UPDATE_CART, {
    onCompleted: () => {
      toast.warning("Produkt został usunięty z koszyka.")
      refetch()
    },
    onError: error => {
      if (error) {
        console.warn(error, error.graphQLErrors[0].messagee)
      }
    },
  })

  // Handle remove product click
  const handleRemoveProductClick = (event, cartKey, products) => {
    const product = products.find(item => cartKey === item.cartKey)

    event.stopPropagation()
    if (product) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "removeFromCart",
        ecommerce: {
          remove: {
            products: [
              {
                name: product.name,
                id: product.productId,
                price: product.price,
                quantity: product.qty,
              },
            ],
          },
        },
      })
    }
    if (products.length) {
      const newQty = 0
      const updatedItems = getUpdatedItems(products, newQty, cartKey)
      updateCart({
        variables: {
          input: {
            clientMutationId: v4(),
            items: updatedItems,
          },
        },
      })
    }
  }

  return (
    <div className="row">
      <div className="col-lg-8">
        <PageSubHeader title="Produkty" />
        {cart ? (
          <div className="cart__table">
            <div className="cart__table-header">
              <div className="row">
                <div className="col-md-7">
                  <small>Nazwa</small>
                </div>
                <div className="col-md-2">
                  <small>Liczba sztuk</small>
                </div>
                <div className="col-md-2">
                  <small>Kwota (brutto)</small>
                </div>
                <div className="col-md-1"></div>
              </div>
            </div>
            {cart &&
              cart.products.map((item, index) => (
                <div className="cart__table-item" key={index}>
                  <div className="row align-items-center">
                    <div className="col-1">
                      <div className="cart__table-item-image">
                        {item.image && (
                          <img src={item.image.sourceUrl} alt={item.name} />
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <small className="cart__table-mobile-label">Nazwa</small>
                      {item.name}
                    </div>
                    <div className="col-md-2">
                      <small className="cart__table-mobile-label">
                        Liczba sztuk
                      </small>
                      {item.qty} szt.
                    </div>
                    <div className="col-md-2">
                      <small className="cart__table-mobile-label">
                        Kwota (brutto)
                      </small>
                      {item.totalPrice}
                    </div>
                    <div className="col-md-1 text-center">
                      <button
                        className="cart__table-remove"
                        onClick={event => {
                          handleRemoveProductClick(
                            event,
                            item.cartKey,
                            cart.products
                          )
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <p className="row__products-empty-info">
            Brak produktów w <strong>koszyku</strong>.
          </p>
        )}
      </div>

      <div className="col-xl-3 offset-xl-1 col-lg-4">
        <div className="order__summary">
          {data && data.cart.discountTotal !== "0.00zł" && (
            <h4>
              Naliczony rabat <strong>-3.5%</strong>
              <br /> w wysokości <strong>{data.cart.discountTotal}</strong>
            </h4>
          )}
          <p>Łączna kwota zamówienia</p>
          <h3>{cart && cart.totalSubProductsPrice}</h3>
          {cart && (
            <div className="order__summary-shipping-info">
              <p>
                {totalParsed < 2000 ? (
                  <>
                    Do darmowej wysyłki <br /> brakuje Ci{" "}
                    <NumberFormat
                      value={2000 - totalParsed}
                      displayType="text"
                      thousandSeparator=","
                      decimalSeparator="."
                      suffix=".00zł"
                    />
                  </>
                ) : (
                  "Osiągnąłeś próg darmowej wysyłki!"
                )}
              </p>
            </div>
          )}
          <Button
            type="link"
            to={isLoggedIn ? "/zamowienie/" : "/logowanie?checkout=1"}
            disabled={!productsCount}
          >
            Dostawa i płatność
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CartContent
