import "styles/pages/cart.scss"

import React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import CartContent from "components/CartContent/CartContent"

const Cart = () => {
  const title = "Koszyk"

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />
      <section className="cart">
        <div className="container-fluid">
          <PageHeader title={title} />
          <CartContent />
        </div>
      </section>
    </Layout>
  )
}

export default Cart
