import "./breadcrumbs.scss"

import React from "react"
import { Link } from "gatsby"

const Breadcrumbs = ({ title, childOf, className }) => {
  return (
    <section className={`breadcrumbs${className ? ` ${className}` : ""}`}>
      <div className="container-fluid">
        <div className="breadcrumbs__wrapper">
          <Link to="/">Strona główna</Link>
          {childOf && (
            <Link
              to={`/${childOf
                .toLowerCase()
                .replace(",", "-")
                .replace(" ", "")}/`}
            >
              {childOf.replace("-", " ")}
            </Link>
          )}
          <strong>{title}</strong>
        </div>
      </div>
    </section>
  )
}

export default Breadcrumbs
